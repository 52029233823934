<template>
  <DataTable
    ref="dataTableRef"
    :loading="signaturesStore.loading"
    :data="signaturesStore.signatures"
    :page-size="pageSize"
    :columns="columns"
    :row-key="rowKey"
    :search="true"
    :scroll-x="800"
    @update-sorter="onSorterOrFilterChange"
    @update-page="onPaginationChange"
    @update-filter="onSorterOrFilterChange"
  >
    <n-button type="primary" @click="() => openFormModal()">
      <img src="/images/attachment.svg" class="sr__attachment__svg" alt="" />&nbsp;Add new record
    </n-button>
  </DataTable>

  <LazySignatureFormModal
    ref="signatureFormModal"
    :signature="signaturesStore.selectedSignature"
    @on-submit="onSubmit"
    @on-show-update="onShowUpdate"
  />

  <LazyConfirmModal ref="confirmDeleteModalRef" :on-confirm="onRemoveConfirm">
    <template #header>
      <h5>Confirm Deletion</h5>
    </template>

    <div class="text-center">
      <p class="mb-2">You're about to remove this from your account.</p>
      <h1 class="mb-2">Are you sure?</h1>
    </div>
  </LazyConfirmModal>

  <LazyConfirmModal
    ref="confirmResendEmailsModalRef"
    :on-confirm="onResendEmailsConfirm"
    :positive-text="'Yes. Send it'"
  >
    <template #header>
      <h5>Confirm Sending Emails</h5>
    </template>

    <div class="text-center">
      <p class="mb-2">{{ confirmResendEmailsContent }}</p>
      <h1 class="mb-2">Are you sure?</h1>
    </div>
  </LazyConfirmModal>

  <template v-if="isSignNowStepsEditTutorialNeeded">
    <SignNowStepsEditGuide ref="signNowStepsEditTutorialModalRef" :on-submit="onSignNowStepsEditTutorialSubmit" />
  </template>

  <template v-if="signaturesStore.signNowIframeSource">
    <SignNowModal
      ref="signNowModalRef"
      title=""
      :iframe-source="signaturesStore.signNowIframeSource"
      @on-show-update="reloadInvoiceDocumentInfo"
    />
  </template>
</template>

<script setup lang="ts">
import { NButton, NIcon, NProgress, NSpace, type DataTableCreateRowKey } from 'naive-ui';
import type { CreateSignatureDto, SignatureDto, UpdateSignatureDto } from '../../base/common/dtos/signature.dto';
import type { DataTableColumn } from '../../base/common/interfaces/data-table/data-table-props.interface';
import SignatureFormModal from '../components/SignatureFormModal.vue';
import type {
  DataTableFilterEmitParams,
  DataTablePaginationEmitParams,
  DataTableSorterEmitParams,
} from '../../base/common/interfaces/data-table/data-table-emits.interface';
import DotsMenu from '../../base/components/DotsMenu.vue';
import { Pencil, Trash, FileDownload } from '@vicons/tabler';
import type ConfirmModal from '../../base/components/ConfirmModal.vue';
import { SIGNATURE_STATUS } from '../../base/common/enums/signature-status';
import type SignNowModal from '../components/sign-now-modal.vue';
import type { ComponentExposed } from 'vue-component-type-helpers';
import type DataTable from '../../base/components/DataTable.vue';
import SignNowStepsEditGuide from '../../base/components/modals/SignNowStepsEditGuide.vue';

definePageMeta({
  layout: 'default',
  middleware: ['authenticated', 'sign-up-successful'],
});

const { $toastSuccess } = useNuxtApp();
const { data: $auth } = useAuth();
const signaturesStore = useSignaturesStore();
const invoicesStore = useInvoicesStore();

const CONFIRM_RESEND_EMAILS_CONTENT = {
  [SIGNATURE_STATUS.CollectingSignatures]: `You're about to send request signing emails to current step signers once again.`,
  [SIGNATURE_STATUS.WaitingSigningFields]: `You're about to send request signing emails to first step signers. After that you can't be able to edit record and signing fields.`,
};

const signatureFormModal = ref<InstanceType<typeof SignatureFormModal> | null>(null);
const confirmDeleteModalRef = ref<InstanceType<typeof ConfirmModal> | undefined>();
const downloadAttachmentId = ref<number | null>(null);
const downloadInProgress = ref<boolean>(false);
const signatureIdToRemove = ref<number | null>(null);
const lastDataTableOptions: Ref<GetSignaturesParams> = ref({});
const dataTableRef = ref<ComponentExposed<typeof DataTable> | undefined>();
const signNowModalRef = ref<ComponentExposed<typeof SignNowModal> | undefined>();
const signNowStepsEditTutorialModalRef = ref<ComponentExposed<typeof SignNowStepsEditGuide> | undefined>();
const confirmResendEmailsModalRef = ref<InstanceType<typeof ConfirmModal> | undefined>();
const signatureIdToResend = ref<number | null>(null);
const confirmResendEmailsContent = ref<string>(CONFIRM_RESEND_EMAILS_CONTENT[SIGNATURE_STATUS.WaitingSigningFields]);

const isSignNowStepsEditTutorialNeeded = computed(() => !($auth.value?.tutorials?.skipSignNowStepsEditPdf ?? false));

const pageSize = 10;
const rowKey: DataTableCreateRowKey<SignatureDto> = (row: SignatureDto) => row.id;
const columns: DataTableColumn<SignatureDto>[] = [
  {
    type: 'expand',
    expandable: (rowData: SignatureDto) => rowData.status !== SIGNATURE_STATUS.SigningComplete,
    renderExpand: (rowData: SignatureDto) => {
      return h(NSpace, () => [
        rowData.status === SIGNATURE_STATUS.WaitingSigningFields &&
          h(
            NButton,
            { type: 'primary', onClick: () => openSignNowModal(rowData.id) },
            { default: () => 'Open PDF Editor' },
          ),
        rowData.canAgentSign &&
          rowData.status === SIGNATURE_STATUS.CollectingSignatures &&
          h(
            NButton,
            { type: 'primary', onClick: () => openSignNowModal(rowData.id, true) },
            { default: () => 'Sign PDF' },
          ),
        rowData.canEmailsBeSent &&
          rowData.status !== SIGNATURE_STATUS.SigningComplete &&
          h(
            NButton,
            { type: 'primary', onClick: () => openConfirmResendEmailsModal(rowData.id) },
            { default: () => 'Send request signing emails' },
          ),
      ]);
    },
  },
  {
    title: 'Status',
    key: 'status',
    fixedResponsive: { minWidth: 426, fixedPosition: 'left' },
    width: 200,
    sorter: true,
  },
  {
    title: 'Description',
    key: 'description',
    fixed: 'left',
    sorter: true,
  },
  {
    title: 'Memo',
    key: 'memo',
    fixedResponsive: { minWidth: 400 },
    minWidth: 200,
    sorter: true,
  },
  {
    title: 'Created At',
    key: 'createdAt',
    fixedResponsive: { minWidth: 200 },
    sorter: true,
    render(rowData) {
      return rowData.createdAt ? formatDate(rowData.createdAt) : '';
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    minWidth: 50,
    render: (rowData: SignatureDto) => {
      if (downloadInProgress.value && rowData.id === downloadAttachmentId.value) {
        return h(NProgress, {
          processing: true,
          type: 'line',
          showIndicator: false,
          percentage: 100,
          color: 'green',
        });
      } else {
        return h(DotsMenu, {
          menuItems: [
            {
              label: 'Download attachment',
              icon: () => {
                return h(NIcon, null, {
                  default: () => h(FileDownload),
                });
              },
              props: {
                onClick: () => {
                  downloadAttachment(rowData.id);
                },
              },
            },
            {
              label: 'Edit',
              disabled: !rowData.canBeEdited,
              icon: () => {
                return h(NIcon, null, {
                  default: () => h(Pencil),
                });
              },
              props: {
                onClick: () => {
                  if (rowData.canBeEdited) {
                    openFormModal(rowData.id);
                  }
                },
              },
            },
            {
              label: 'Delete',
              disabled: !rowData.canBeDeleted,
              icon: () => {
                return h(NIcon, null, {
                  default: () => h(Trash),
                });
              },
              props: {
                onClick: () => {
                  if (rowData.canBeDeleted) {
                    openConfirmDeleteModal(rowData.id);
                  }
                },
              },
            },
          ],
        });
      }
    },
  },
];

const openFormModal = async (id?: number) => {
  try {
    if (id) {
      signaturesStore.selectedSignature = await signaturesStore.getSignature(id);
    }
    signatureFormModal.value?.openModal();
  } catch {}
};

const onSubmit = async (model: CreateSignatureDto | UpdateSignatureDto) => {
  try {
    if (signaturesStore.selectedSignature) {
      await signaturesStore.updateSignature(signaturesStore.selectedSignature.id, model);
    } else if ('uploadedFileIds' in model && model.uploadedFileIds.length) {
      const signature = await signaturesStore.createSignature(model);
      openSignNowModal(signature.id);
    }

    signatureFormModal.value?.clearFileList();
    signatureFormModal.value?.closeModal();
  } catch {}
};

const onShowUpdate = (value: boolean): void => {
  if (!value && signaturesStore.selectedSignature) {
    signaturesStore.selectedSignature = null;
  }
};

const onSorterOrFilterChange = async (
  options: DataTableSorterEmitParams | DataTableFilterEmitParams,
): Promise<void> => {
  const fetchOptions = getDataTableFetchOptions<SignatureDto>(options, pageSize);
  await signaturesStore.getSignatures(fetchOptions);
};

const openConfirmDeleteModal = (id: number): void => {
  signatureIdToRemove.value = id;
  confirmDeleteModalRef.value?.openModal();
};

const onRemoveConfirm = async () => {
  if (!signatureIdToRemove.value) {
    return;
  }

  await signaturesStore.removeSignature(signatureIdToRemove.value);
  await reloadTable();

  signatureIdToRemove.value = null;
};

const downloadAttachment = async (id: number) => {
  downloadAttachmentId.value = id;
  downloadInProgress.value = true;

  try {
    await signaturesStore.downloadAttachment(id);
  } catch {
  } finally {
    downloadInProgress.value = false;
    downloadAttachmentId.value = null;
  }
};

const onPaginationChange = async (options: DataTablePaginationEmitParams): Promise<void> => {
  const fetchOptions = getDataTableFetchOptions<SignatureDto>(options, pageSize);

  await signaturesStore.getSignatures(fetchOptions);

  Object.assign(lastDataTableOptions.value, fetchOptions);
};

async function openSignNowModal(id: number, isSigning: boolean = false) {
  try {
    await signaturesStore.getSignNowIframeSource(id, isSigning);

    if (!signNowModalRef.value) {
      return;
    }
    if (isSignNowStepsEditTutorialNeeded.value) {
      openSignNowStepsEditTutorialModal();

      return;
    }

    signNowModalRef.value.openModal();
  } catch {}
}

function openSignNowStepsEditTutorialModal() {
  if (signNowStepsEditTutorialModalRef.value) {
    signNowStepsEditTutorialModalRef.value.openModal();
  }
}

async function onSignNowStepsEditTutorialSubmit(skip: boolean) {
  if (skip) {
    await invoicesStore.skipSignNowEdit('skipSignNowStepsEditPdf');
  }

  if (signNowModalRef.value) {
    signNowModalRef.value.openModal();
    signNowStepsEditTutorialModalRef.value?.closeModal();
  }
}

async function reloadInvoiceDocumentInfo() {
  await reloadTable();
}

async function reloadTable() {
  if (dataTableRef.value) {
    dataTableRef.value.resetTable();

    await initSignaturesDataTable();
  }
}

async function initSignaturesDataTable(): Promise<void> {
  const fetchOptions = lastDataTableOptions.value ?? { take: pageSize };

  await signaturesStore.getSignatures(fetchOptions);
}

const openConfirmResendEmailsModal = (id: number): void => {
  signatureIdToResend.value = id;
  const signature = signaturesStore.signatures.data.find((signature) => signature.id === signatureIdToResend.value);
  if (signature && signature.status !== SIGNATURE_STATUS.SigningComplete) {
    confirmResendEmailsContent.value = CONFIRM_RESEND_EMAILS_CONTENT[signature.status];
  }

  confirmResendEmailsModalRef.value?.openModal();
};

const onResendEmailsConfirm = async () => {
  if (!signatureIdToResend.value) {
    return;
  }

  try {
    await signaturesStore.resendEmails(signatureIdToResend.value);
    $toastSuccess('Emails have been sent');
    await reloadTable();
  } catch {}
  signatureIdToResend.value = null;
};

function isIframeCallableFunctionName(functionName: unknown): functionName is keyof typeof iframeCallableFunctions {
  return Boolean(functionName && typeof functionName === 'string' && functionName in iframeCallableFunctions);
}

const iframeMessageEventListener = ({ data }: MessageEvent) => {
  const functionName: unknown = data.func;
  if (isIframeCallableFunctionName(functionName)) {
    iframeCallableFunctions[functionName]?.(data.data);
  }
};

const iframeCallableFunctions = {
  reloadIframe: () => signNowModalRef.value?.reloadIframe(),
  openConfirmResendEmailsModal: async (signatureId: string) => {
    signNowModalRef.value?.closeModal();
    openConfirmResendEmailsModal(Number(signatureId));
  },
  closeIframeModal: () => signNowModalRef.value?.closeModal(),
} as const;

onMounted(async () => {
  window.addEventListener('message', iframeMessageEventListener);
  await initSignaturesDataTable();
});

onUnmounted(() => {
  window.removeEventListener('message', iframeMessageEventListener);
});
</script>

<style scoped lang="scss">
.sr__attachment__svg {
  width: 16px;
  height: 16px;
}
</style>
